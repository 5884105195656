$breakpoint1: 1120px;
$breakpoint2: 960px;
$breakpoint3: 790px;
$breakpoint4: 770px;

.subscribe__section {
	padding-top: 50px;
	background-image: url('../../../public/images/bg_subscribe.jpg');
	background-repeat: repeat-y;
	background-position: right;
	min-height: 400px;
	background-color: white;
	padding-bottom: 80px;

	@media (max-width: $breakpoint1) {
		background-position: 140% center;
	}

	@media (max-width: $breakpoint2) {
		background-position: calc(50% + 220px) 270px;
	}

	h2 {
		color: black;
	}

	p {
		max-width: 660px;
		padding-right: 20px;

		@media (max-width: $breakpoint2) {
			// width: 90%;
		}

		$breakpoint3: 790px;
		@media (max-width: $breakpoint4) {
			// width: 100%;
		}
	}

	.subscribe_content {
		display: flex;

		@media (max-width: $breakpoint3) {
			flex-direction: column;
		}
	}

	.column {
		position: relative;
		width: calc(50% - 10px);

		@media (max-width: $breakpoint3) {
			left: 20px;
			width: calc(100% - 40px);
		}

		&:last-child {
			overflow-x: hidden;
			display: flex;
			justify-content: center;

			@media (max-width: $breakpoint3) {
				margin-top: 25px;
			}
		}
	}

	.subscribe_team {
		margin-top: 50px;
	}
}
