$breakpoint1: 1120px;
$breakpoint2: 770px;
$breakpoint3: 680px;
$breakpoint4: 600px;
$breakpoint5: 460px;

.when__section {
	height: 935px;
	background-image: url(../../../public/images/bg_where.svg);
	background-position: 50% -179px;
	background-repeat: no-repeat;
	background-color: white;

	@media (max-width: $breakpoint1) {
		background-position: 50% -79px;
		height: 1010px;
	}

	@media (max-width: $breakpoint2) {
		background-image: url(../../../public/images/bg_where_mobile.svg);
		background-position: 50% 200px;
		height: 950px;
	}

	h2 {
		color: black;
		margin-top: 60px;

		@media (max-width: $breakpoint1) {
			margin-top: 160px;
		}
	}

	.date {
		margin: 10px 0 0;
		font-size: 38px;
		font-weight: 600;

		@media (max-width: $breakpoint2) {
			font-size: 38px;
		}

		// @media (max-width: $breakpoint3) {
		// 	margin-left: 40px;
		// }
	}

	.title {
		width: fit-content;
		font-size: 40px;
		font-weight: 700;
		text-decoration: underline;
		margin-bottom: 15px;

		@media (max-width: $breakpoint2) {
			font-size: 30px;
		}

		@media (max-width: $breakpoint4) {
			font-size: 24px;
			left: 64px;
		}

		@media (max-width: $breakpoint2) {
		}
	}

	.where {
		position: relative;
		width: fit-content;
		text-align: center;

		p {
			width: 100%;
		}
	}

	.online {
		@extend .where;

		top: -20px;
		left: calc(50% + 80px);

		@media (max-width: $breakpoint2) {
			top: 350px;
			left: calc(50% - 187px);
		}

		@media (max-width: $breakpoint5) {
			left: calc(50% - 167px);
		}
	}

	.in-person {
		@extend .where;

		top: 136px;
		left: calc(50% - 340px);
		color: white;

		@media (max-width: $breakpoint2) {
			top: -38px;
			left: calc(50% + 35px);
		}

		@media (max-width: $breakpoint4) {
			top: -38px;
			left: calc(50% + 35px);
		}
	}

	.logos {
		position: relative;
		display: flex;
		align-items: center;
		top: 20px;

		@media (max-width: $breakpoint4) {
			left: -19px;
			position: relative;
		}

		img {
			margin-right: 30px;

			@media (max-width: $breakpoint4) {
				width: 90px;
			}
		}
	}
}
