.atendee_form {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	transition: left 0.5s;
	max-width: 100%;

	label {
		display: inline-block;
		margin-bottom: 5px;
	}

	input {
		&[type='text'] {
			display: block;
		}

		&[name='new_position'] {
			display: inline-block !important;
		}
	}
	.new_position {
		height: 55px;
	}

	.slide {
		position: relative;
		display: flex;
		width: 100%;
		flex-shrink: 0;
		justify-content: center;
		align-items: flex-start;
		background-color: rgba(255, 255, 255, 0.6);
		padding-bottom: 70px;

		&:last-child {
			flex-direction: column;
			align-items: center;
		}
	}

	.workat__content {
		display: flex;
		flex-direction: column;
	}

	.button--primary {
		margin: 5px 0 0 5px;
	}

	.button_next {
		font-size: 16px;
		position: absolute;
		bottom: 0;
		right: 4px;
	}

	.button_prev {
		@extend .button_next;

		left: 0;
		right: inherit;
	}

	.button--submit {
		margin-top: 20px;
	}

	#work_with_options > div:last-child {
		margin-bottom: 10px;
	}

	td {
		font-size: 16px;
		&:first-child {
			text-align: right;
			color: var(--site-primary-color);
			padding-right: 10px;
		}
	}

	.sent-message {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		position: absolute;

		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		img {
			width: 100px;
		}

		p {
			margin-top: 0;
			text-align: center;
		}
	}
}
