$breakpoint1: 1460px;
$breakpoint2: 1024px;
$breakpoint3: 560px;
$breakpoint4: 460px;

.mentors__section {
	h3 {
		font-size: 32px;
		margin-bottom: 15px;
	}

	.info_grid {
		display: flex;
		flex-wrap: wrap;

		li {
			position: relative;
			width: calc(100% / 4);
			height: 450px;
			background-color: #383d46;

			@media (max-width: $breakpoint1) {
				width: calc(100% / 3);
			}

			@media (max-width: $breakpoint2) {
				width: calc(100% / 2);
			}

			@media (max-width: $breakpoint3) {
				width: 100%;
			}

			&:hover,
			&:active {
				.image_cover {
					top: 10%;
					left: 10%;
					width: 60%;
					height: 40%;
					border-radius: 10px;
				}
			}
		}
	}

	.image_cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 2;
		border-radius: 0;
		transition: all 0.3s ease-out;
	}

	.infos {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		z-index: 1;
		backdrop-filter: blur(10px);
		color: white;
		padding: 270px 10% 10% 10%;
	}

	.mentor__name {
		display: flex;
		text-decoration: none;
		color: white;
	}

	.logo_linkedin {
		position: relative;
		top: 1px;
		margin-right: 10px;
		width: 25px;
		height: 25px;
	}

	.image_background {
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
		opacity: 0.2;
	}
}
