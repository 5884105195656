.team_form {
	width: 100%;
	max-width: 470px;
	height: 355px;
	background-color: rgba(255, 255, 255, 0.7);

	select {
		width: 85%;

		@media (max-width: 790px) {
			width: calc(100% - 50px);
		}
	}

	img {
		width: 100px;
	}

	.error {
		visibility: visible;
		height: 24px;
	}

	.index__label {
		width: 25px;
		margin-right: 5px;
		display: inline-block;
		text-align: right;
	}

	.select__wrapper {
		margin-bottom: 10px;
	}

	.error {
		margin: 0 0 10px 22px;
		position: relative;
		visibility: visible;
	}

	.button {
		margin-left: 30px;
	}

	.subscribed_screen {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		p {
			text-align: center;
			margin-top: 15px;
		}
	}
}
