@import 'reset-css/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;700&family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

$breakpoint1: 770px;
$breakpoint2: 680px;
$breakpoint3: 460px;

// Variables
:root {
	--site-primary-color: #ff3d03;
	--site-header-width: 72px;
}

// Tags redefinition

* {
	box-sizing: border-box;
}

body {
	font-family: 'Barlow', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	font-weight: 500;
	max-width: 100%;
	width: 100%;
	height: 100%;
}

main {
	font-size: 20px;
	line-height: 24px;
	box-sizing: border-box;
}

p {
	padding: 0 60px;

	@media (max-width: $breakpoint1) {
		padding: 0 20px;
		font-size: 16px;
		line-height: 24px;
	}
}

strong,
b {
	font-weight: 700;
}

h2 {
	display: inline-block;
	position: relative;
	margin: 60px 0 35px 60px;
	font-size: 48px;
	line-height: 54px;
	color: white;

	@media (max-width: $breakpoint1) {
		font-size: 30px;
		line-height: 35px;
		margin-left: 20px;
	}

	@media (max-width: $breakpoint2) {
		margin-top: 30px;
	}

	@media (max-width: $breakpoint3) {
		font-size: 24px;
		line-height: 30px;
	}

	span {
		display: block;
	}
}

input {
	box-sizing: border-box;
}

strong {
	color: var(--site-primary-color);
}

input {
	font-size: 20px;
	border-radius: 5px;
	border: 2px solid var(--site-primary-color);
	padding: 10px 15px;
	accent-color: var(--site-primary-color);

	&:focus {
		outline: none;
	}

	&[type='radio'] {
		display: inline;
	}

	&[id='name'] {
		width: 100px;
	}

	& + label {
		display: inline;
		color: var(--site-primary-color);
		margin-left: 5px;
	}

	&::placeholder {
		color: lightgray;
		font-family: 'Barlow', sans-serif;
		font-weight: 700;
	}

	&[placeholder='Outro'] {
		margin-bottom: 5px;
	}
}

select {
	display: inline-block;
	height: 47px;
	border: 2px solid var(--site-primary-color);
	border-radius: 5px;
	padding: 0 15px;
	font-size: 16px;
	background-color: white;
	line-height: 1.5em;
	padding: 0.5em 3.5em 0.5em 1em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	// width: 310px;

	/* reset */
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;

	//
	background-image: linear-gradient(45deg, transparent 50%, black 50%),
		linear-gradient(135deg, black 50%, transparent 50%),
		linear-gradient(to right, white, white);
	background-position: calc(100% - 20px) calc(1em + 6px),
		calc(100% - 15px) calc(1em + 6px), 100% 0;
	background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
	background-repeat: no-repeat;

	&:focus {
		background-image: linear-gradient(
				45deg,
				var(--site-primary-color) 50%,
				transparent 50%
			),
			linear-gradient(135deg, transparent 50%, black 50%),
			linear-gradient(to right, white, white);
		background-position: calc(100% - 15px) 18px, calc(100% - 20px) 18px, 100% 0;
		background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
		background-repeat: no-repeat;
		outline: 0;
	}

	@media (max-width: 430px) {
		width: calc(100% - 20px);
	}
}

// Global classes
.main-grid {
	display: block;
	position: relative;
	margin: 0 auto;
	max-width: 1360px;
}

.button--primary {
	display: flex;
	width: fit-content;
	padding: 10px 15px;
	background-color: var(--site-primary-color);
	border-radius: 5px;
	font-weight: 600;
	color: white;
	border: 0;
	cursor: pointer;
	text-decoration: none;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: #d03405;
	}
}

.button--primary--sm {
	@extend .button--primary;

	padding: 5px 10px;
}

.button--primary--lg {
	@extend .button--primary;

	height: 65px;
	font-size: 40px;
	padding: 10px 65px;
	border-radius: 10px;
	padding-bottom: 15px;
}

.button--secondary {
	@extend .button--primary;

	background-color: transparent;
	color: black;

	&:hover {
		background-color: var(--site-primary-color);
		color: white;
	}
}

.button--secondary--sm {
	@extend .button--secondary;

	padding: 5px 10px;
}

.button--tertiary {
	@extend .button--primary;

	background-color: white;
	color: black;
	border: 2px solid #ff3d03;

	&:hover {
		background-color: #ff3d03;
		color: white;
	}
}

.button--tertiary--sm {
	@extend .button--tertiary;

	padding: 5px 10px;
}

.button--tertiary--lg {
	@extend .button--primary--lg;

	background-color: white;
	color: black;
	border: 2px solid #ff3d03;

	&:hover {
		background-color: #ff3d03;
		color: white;
	}
}

.h2_hr {
	display: block;
	position: relative;
	top: 11px;
	left: -489px;
	width: calc(100% + 560px);
	height: 20px;
	background-image: url(../public/images/h2_hr.png);
	background-position: right;
}

.h2_hr--gray {
	@extend .h2_hr;

	background-image: url(../public/images/h2_hr2.png);
}

.error {
	font-size: 14px;
	margin: 0;
	padding: 0 0 0 8px;
	color: red;
	visibility: hidden;
	position: absolute;
}
