$breakpoint1: 680px;

.mentors__section {
	background-color: white;

	h2 {
		color: black;
		margin-left: 60px;

		@media (max-width: $breakpoint1) {
			margin-left: 20px;
		}
	}

	p {
		padding: 0;

		&.text {
			margin-top: 10px;
		}
	}

	a {
		margin: 10px 0 10px;
	}
}
