$breakpoint1: 1470px;
$breakpoint2: 1040px;
$breakpoint3: 1240px;
$breakpoint4: 840px;
$breakpoint5: 560px;
$breakpoint6: 1160px;
$breakpoint7: 950px;

.prizes__section {
	min-height: 988px;
	background-color: #383d46;
	background-image: url('../../../public/images/bg_prizes.png');
	background-position: right;
	background-repeat: repeat-y;
	padding-bottom: 100px;

	@media (max-width: $breakpoint6) {
		background-position: 100px;
	}

	@media (max-width: $breakpoint7) {
		background-size: 1280px;
		background-position: calc(50% + 400px);
	}

	.prizes__content {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin-top: 160px;

		@media (max-width: $breakpoint4) {
			flex-direction: column;
			align-items: center;
		}

		li {
			position: relative;
			border: 2px solid #ff3d03;
			border-radius: 10px;
			width: calc(100% / 3);
			background-color: white;
			height: 530px;
			margin: 0 30px;
			text-align: center;

			@media (max-width: $breakpoint3) {
				&:nth-child(2) {
					margin: 0 0px;
				}
			}

			@media (max-width: $breakpoint4) {
				padding-bottom: 50px;
				width: 70%;
				min-width: 330px;
			}

			&:first-child {
				margin-left: 20px;

				@media (max-width: $breakpoint4) {
					margin: 0 0 110px 0;
					height: auto;
				}
			}

			&:nth-child(2) {
				height: 450px;

				@media (max-width: $breakpoint4) {
					margin: 0 0 100px 0;
					height: auto;
				}
			}

			&:last-child {
				height: 380px;
				margin-right: 20px;
				padding: 0 15px;

				@media (max-width: $breakpoint4) {
					margin: 0 0 100px 0;
					height: auto;
					padding-bottom: 50px;
				}
			}
		}
	}

	p {
		padding: 1px 15px;
		font-size: 16px;
		font-weight: 300;
		text-align: left;
	}

	.prizes__img {
		position: absolute;
	}

	.prizes__img--first {
		@extend .prizes__img;

		width: 493px;
		top: 273px;
		left: 0;

		@media (max-width: $breakpoint7) {
			width: 323px;
			left: calc((50%) - (323px / 2));
			top: 210px;
		}
	}

	.prizes__img--second {
		@extend .prizes__img;

		width: 350px;
		top: 336px;
		left: 550px;

		@media (max-width: $breakpoint7) {
			width: 270px;
			top: 532px;
			left: calc((50%) - (270px / 2));
		}
	}

	.button {
		position: absolute;
		top: 780px;
		left: 123px;
		box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);

		@media (max-width: $breakpoint4) {
			margin-top: 0;
		}

		// @media (max-width: $breakpoint5) {
		// 	font-size: 27px;
		// 	padding: 10px 30px;
		// }

		@media (max-width: $breakpoint7) {
			top: 850px;
			left: calc(50% - (230px / 2));
		}
	}
}
