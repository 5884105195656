.hero__section {
	$breakpoint1: 1330px;
	$breakpoint2: 1120px;
	$breakpoint3: 910px;
	$breakpoint4: 770px;
	$breakpoint5: 550px;

	background-color: #171717;
	position: relative;
	display: block;
	width: 100vw;
	height: 724px;
	background-image: url('../../../public/images/hero.jpg');
	background-position: right bottom;
	background-size: auto 1000px;
	background-repeat: no-repeat;

	@media (max-width: $breakpoint1) {
		height: 530px;
		background-size: auto 700px;
	}

	@media (max-width: $breakpoint2) {
		height: 750px;
	}

	@media (max-width: $breakpoint3) {
		height: 850px;
	}

	@media (max-width: $breakpoint4) {
		height: 670px;
		background-size: auto 500px;
	}

	@media (max-width: $breakpoint5) {
		height: 750px;
		background-position: 80% bottom;
	}

	p {
		color: white;
		width: 500px;

		@media (max-width: $breakpoint3) {
			width: 100%;
		}

		@media (max-width: $breakpoint4) {
			width: 100%;
		}
	}
}
