$breakpoint1: 1360px;
$breakpoint2: 1120px;
$breakpoint3: 940px;
$breakpoint4: 770px;
$breakpoint5: 680px;

.focus__section {
	position: relative;
	display: block;
	width: 100vw;
	height: 574px;
	background-image: url('../../../public/images/bg_foca.jpg');
	background-position: 60% bottom;
	background-size: auto 574px;
	background-repeat: no-repeat;
	background-color: #131313;
	margin-bottom: 100px;

	@media (max-width: $breakpoint3) {
		height: 734px;
	}

	@media (max-width: $breakpoint5) {
		height: 710px;
	}

	h2 {
		@media (max-width: $breakpoint5) {
			width: 200px;
		}
	}

	p {
		position: relative;
		width: 690px;
		color: white;

		@media (max-width: $breakpoint4) {
			width: 100%;
		}

		@media (max-width: $breakpoint5) {
			top: 0;
			margin-top: 40px;
			width: 100%;
		}
	}

	.focus__wrapper {
		height: 100%;
	}

	.car {
		position: absolute;
		top: 293px;
		left: 547px;
		width: auto;
		height: 337px;

		@media (max-width: $breakpoint1) {
			top: inherit;
			bottom: -50px;
			left: inherit;
			width: auto;
			height: 257px;
			right: 32px;
		}

		@media (max-width: $breakpoint5) {
			left: inherit;
			width: calc(100% - -177px);
			height: auto;
			right: -38px;
			bottom: -65px;
		}
	}

	.button {
		position: relative;
		top: 74px;
		left: 67px;

		@media (max-width: $breakpoint4) {
			top: 0;
			left: 0;
			margin: 110px auto 0;
		}

		@media (max-width: $breakpoint5) {
			font-size: 30px;
			margin-top: 40px;
		}
	}
}
