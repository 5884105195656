.header__session {
	background-color: #efefef;
	height: var(--site-header-width);

	.header__content {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: space-between;
		padding: 0 20px;
	}

	.h1__content {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}

	.logo_nstech {
		width: auto;
		height: 44px;

		@media (max-width: 440px) {
			display: none;
		}
	}

	.logo_hackathon {
		width: 155px;
		position: relative;
		top: -4px;
		margin-left: 8px;

		@media (max-width: 440px) {
			top: 1px;
			margin-left: 0;
		}
	}

	.event_date {
		color: var(--site-primary-color);
		margin-left: 13px;
		font-weight: 600;
		font-size: 20px;

		@media (max-width: 1130px) {
			display: none;
		}
	}

	.menu__wrapper {
		display: flex;
	}

	ul {
		display: flex;

		@media (max-width: 960px) {
			display: none;
		}
	}

	li {
		margin: 0 5px;
	}
}
