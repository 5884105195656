.footer__section {
	position: fixed;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 310px;
	background-color: black;
	z-index: -2;

	img {
		width: 200px;
	}
}
